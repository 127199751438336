var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "breadcrumb_div" }, [
    _c(
      "div",
      {
        staticClass: "breadcrumb_sty",
        staticStyle: { height: "40px", "line-height": "40px" },
      },
      [
        _c(
          "rs-breadcrumb",
          _vm._l(_vm.iframeArr, function (item, index) {
            return _c("rs-breadcrumb-item", {
              key: item.name,
              attrs: { to: { path: item.path } },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }