<template>
  <div class="iframe-container-box">
    <div class="searchForm">
      <renderForm :formData.sync="searchForm" :list="ruleForm" ref="ruleFormRef" labelWidth="70">
        <template v-slot:operation>
          <rs-button type="primary" size="small" @click="search" :loading="buttonLoading">查询</rs-button>
          <rs-button @click="clear" class="hover-style" type="text" size="small">重置</rs-button>
        </template>
      </renderForm>
    </div>
    <div>
      <div class="box">
        <rs-row>
          <rs-col :span="24">
            <rs-button type="primary" size="mini" @click="edit('', 'create')">新增账户</rs-button>
          </rs-col>
        </rs-row>
      </div>
      <rs-table
        class="my-table"
        :data="tableData"
        highlight-current-row
        border
        tooltip-effect="light"
        height="calc(100vh - 150px)"
        @row-dblclick="handleRowClick"
        style="width: 100%;"
      >
        <rs-table-column label="序号" type="index" align="left" width="70"></rs-table-column>
        <rs-table-column label="账户ID" prop="userCode" align="left"></rs-table-column>
        <rs-table-column label="登录名" prop="loginName" align="left"></rs-table-column>
        <rs-table-column label="员工姓名" prop="userName" align="left"></rs-table-column>
        <rs-table-column label="岗位名称" prop="stationName" show-overflow-tooltip align="left"></rs-table-column>
        <rs-table-column label="所属组织" prop="orgName" show-overflow-tooltip align="left"></rs-table-column>
        <rs-table-column prop="mobile" width="130" label="手机" align="left"></rs-table-column>
        <rs-table-column prop="email" label="邮箱" show-overflow-tooltip align="left"></rs-table-column>
        <rs-table-column prop="createTime" show-overflow-tooltip label="创建时间" align="left"></rs-table-column>
        <rs-table-column prop="status" label="状态" :formatter="formatStatus" align="left"></rs-table-column>
        <rs-table-column label="操作" width="150" fixed="right" align="center">
          <template slot-scope="scope">
            <div>
              <rs-button size="small" @click.stop="handleAuth(scope)" type="text">管理权限</rs-button>
            </div>
            <div>
              <rs-button size="small" @click.stop="handleChange(scope)" type="text">{{ scope.row.status ? '冻结账号' : '启用账号' }}</rs-button>
            </div>
            <div>
              <rs-button v-if="scope.row.status === true" type="text" size="small" @click.stop="resetPassword(scope.row)">重置密码</rs-button>
            </div>
          </template>
        </rs-table-column>
      </rs-table>
      <div style="margin-top: 10px">
        <rs-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="prev, pager, next, sizes, jumper"
          :total="total"
          background
        ></rs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Http from '@/api/account'
import Utils from '@/utils/utils'
import breadCrumb from '@/components/breadCrumb'
export default {
  name: 'accountManageList',
  components: {
    breadCrumb
    // AccountForm
  },
  data() {
    return {
      showMore: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      pageSize: 10,
      loading: false,
      buttonLoading: false,
      searchForm: {
        userName: '',
        status: '',
        mobile: ''
      },
      ruleForm: [
        {
          type: 'input',
          label: '员工姓名',
          key: 'userName'
        },
        {
          type: 'select',
          label: '员工状态',
          key: 'status',
          optionList: [
            { value: true, label: '已启用' },
            { value: false, label: '已停用' }
          ],
          optionProps: { label: 'label', value: 'value' }
        },
        {
          type: 'input',
          label: '手机号码',
          key: 'mobile'
        },
        {
          slot: 'operation'
        }
      ]
    }
  },
  methods: {
    // 重置密码
    resetPassword(row) {
      this.$confirm('确定重置密码？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Http.resetPwd({
            id: row.id
          }).then(res => {
            // console.log(res);
            this.getList()
            this.$message.success('重置成功')
          })
        })
        .catch(() => {
          console.log('用户已取消')
        })
    },
    // 清除搜索条件
    clear() {
      this.searchForm = {
        userName: '',
        status: '',
        mobile: ''
      }
    },
    getList() {
      let params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        userName: this.searchForm.userName,
        status: this.searchForm.status,
        mobile: this.searchForm.mobile
      }
      let that = this
      Http.getUserInfoList(params)
        .then(res => {
          this.buttonLoading = false
          that.total = res.data.data.total
          if (res.data.data.list.length >= 0) {
            that.tableData = res.data.data.list
          }
        })
        .catch(error => {
          this.buttonLoading = false
        })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
    formatStatus(row, column) {
      return row.status ? '已启用' : '已停用'
    },
    search() {
      this.buttonLoading = true
      this.currentPage = 1
      this.getList()
    },
    edit(row, type) {
      if (type === 'create') {
        this.$router.push(`/accountManageEdit`)
      } else {
        this.$router.push(`/accountManageDetail/${type}?id=${row.id}`)
      }
    },
    // 行点击
    handleRowClick(row, column, event) {
      // if(column.label!='操作') {
      this.edit(row, 'view')
      // }
    },
    handleAuth(row) {
      this.edit(row.row, 'auth')
    },
    handleChange(scope) {
      let params = {
        id: scope.row.id,
        status: scope.row.status ? false : true
      }
      Http.changeStatus(params).then(res => {
        this.$message({
          message: '操作成功！',
          type: 'success'
        })
        this.getList()
      })
    }
  },
  created() {
    let _self = this
    this.getList()
    window.addEventListener('storage', function(e) {
      console.log(e, 'storage')
      if (e.key === 'editUser') {
        _self.getList()
      }
      console.log(e.newValue)
    })
  }
}
</script>

<style scoped>
.query-form .rs-form-item {
  margin-bottom: 0;
}
</style>
<style></style>
