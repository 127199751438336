<template>
    <div class="breadcrumb_div">
        <div style="height:40px;line-height:40px;" class="breadcrumb_sty">
            <rs-breadcrumb>
                <rs-breadcrumb-item
                    v-for="(item, index) in iframeArr"
                    :to="{ path: item.path }"
                    :key="item.name">
                </rs-breadcrumb-item>
            </rs-breadcrumb>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        iframeArr: {
            type: Array,
            default: () => []
        }
    },
    created() {

    },
    mounted() {

    }
}
</script>
<style scoped>
/* @import url(); 引入css类 */

</style>